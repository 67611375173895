<template>
  <moe-page title="店铺折扣">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">店铺折扣</div>
            <div class="font-14">店铺级优惠工具，可对全店商品及自选商品进行满件打折、满元减钱、送权益、送优惠券等促销活动的。</div>
          </div>
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/shopDiscount/add')">新增店铺折扣</el-button>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="shopDiscountSearch">
        <el-form-item label="活动状态">
          <moe-select type="discountState" v-model="shopDiscountParams.state" placeholder="选择活动状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="shopDiscountParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="shopDiscountParams.id" @input="(value) => shopDiscountParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-tabs type="border-card" :tabsList="[{ label: '自选商品活动' }, { label: '全店商品活动' }]" @change="handleChange">
        <template #0>
          <moe-table v-if="tabsIndex === 0" ref="shopDiscountTable" url="/shop/discount/list" :params="shopDiscountParams" :number-show="false" :mode="mode">
            <el-table-column prop="id" label="活动编号" min-width="80" />
            <el-table-column label="活动名称" prop="name" :show-overflow-tooltip="true" />
            <el-table-column label="活动详情" prop="contents" min-width="150" :show-overflow-tooltip="true">
              <div slot-scope="{ row }">
                <div v-html="formatterContents(row)"></div>
              </div>
            </el-table-column>
            <el-table-column label="活动时间" min-width="310">
              <div class="df fdc aic jcc" slot-scope="{ row }">
                <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
              </div>
            </el-table-column>
            <el-table-column label="活动状态" min-width="150">
              <div :class="$moe_format.getDiscountStateColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getDiscountState(row.state) }}
              </div>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="150" />
            <el-table-column label="操作" fixed="right" width="400">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button v-if="!['PENDING'].includes(row.state)" type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/shopDiscount/detail`, { id: row.id })">查看活动</el-button>
                <el-button v-if="!['FINISHED', 'CLOSED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/shopDiscount/add`, { id: row.id })">编辑活动</el-button>
                <el-button v-if="['IN_PROGRESS'].includes(row.state)" type="warning" size="mini" icon="el-icon-video-pause" @click="handlePause(row)">暂停</el-button>
                <el-button v-if="['PAUSED', 'FINISHED'].includes(row.state)" type="info" size="mini" icon="el-icon-video-play" @click="handlePlay(row)">重启</el-button>
                <el-button v-if="!['PENDING', 'CLOSED'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>
        <template #1>
          <moe-table v-if="tabsIndex === 1" ref="shopDiscountTable" url="/shop/discount/list" :params="shopDiscountParams" :number-show="false" :mode="mode">
            <el-table-column prop="id" label="活动编号" min-width="80" />
            <el-table-column label="活动名称" prop="name" :show-overflow-tooltip="true" />
            <el-table-column label="活动详情" prop="contents" min-width="150" :show-overflow-tooltip="true">
              <div slot-scope="{ row }">
                <div v-html="formatterContents(row)"></div>
              </div>
            </el-table-column>
            <el-table-column label="活动时间" min-width="310">
              <div class="df fdc aic jcc" slot-scope="{ row }">
                <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
              </div>
            </el-table-column>
            <el-table-column label="活动状态" min-width="150">
              <div :class="$moe_format.getDiscountStateColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getDiscountState(row.state) }}
              </div>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="150" />
            <el-table-column label="操作" fixed="right" width="400">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button v-if="!['PENDING'].includes(row.state)" type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/shopDiscount/detail`, { id: row.id })">查看活动</el-button>
                <el-button v-if="!['FINISHED', 'CLOSED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/shopDiscount/add`, { id: row.id })">编辑活动</el-button>
                <el-button v-if="['IN_PROGRESS'].includes(row.state)" type="warning" size="mini" icon="el-icon-video-pause" @click="handlePause(row)">暂停</el-button>
                <el-button v-if="['PAUSED', 'FINISHED'].includes(row.state)" type="info" size="mini" icon="el-icon-video-play" @click="handlePlay(row)">重启</el-button>
                <el-button v-if="!['PENDING', 'CLOSED'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>
      </moe-tabs>
    </moe-card>

    <moe-dialog :autoHeight="true" :show="showDialog" title="重启活动" width="30%" @close="handleDialogClose()">
      <moe-form
        ref="restartForm"
        :showBack="false"
        :model="restartParams"
        :rules="rules">
        <div class="mb-20 df aic color-info">
          <div class="mr-10"><moe-icon name="reminder" size="26px"></moe-icon></div>
          <div class="font-16">活动时间已过期，请重新设置活动时间并提交重启</div>
        </div>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="活动时间开始时间"
            end-placeholder="活动时间结束时间"
            :picker-options="pickerOptions"
            @change="(datetime) => ([restartParams.startTime, restartParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-form>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageShopDiscountList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      tabsIndex: 0,
      mode: false,
      shopDiscountParams: {
        pageNum: 1,
        pageSize: 10,
        state: '',
        name: '',
        id: '',
        level: 'PART'
      },
      levelOptions: {
        0: 'PART',
        1: 'ALL',
      },
      showDialog: false,
      restartParams: {
        id: '',
        startTime: '',
        endTime: ''
      },
      datetime: [],
      rules: {
        startTime: verify.isEmpty('请选择活动时间'),
      },
      pickerOptions: {
        disabledDate(currentDate) {
          // 获取当前日期
          const today = new Date();
          // 将时间设置为0时0分0秒
          today.setHours(0, 0, 0, 0);

          // 如果当前日期小于今天，则禁用选择
          return currentDate < today;
        }
      },
    }
  },
  methods: {
    shopDiscountSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.shopDiscountParams = {
          pageNum: 1,
          pageSize: 10,
          state: '',
          name: '',
          id: '',
          level: this.levelOptions[this.tabsIndex],
        };
      }

      this.$refs['shopDiscountTable'].searchData();
    },
    handleDialogClose() {
      this.showDialog = false;
      this.$refs['restartForm'].resetFields();
    },
    handleConfirm() {
      this.$refs['restartForm'].validate(() => {
        this.$moe_api.SHOP_DISCOUNT.restartDiscount(this.restartParams).then((data) => {
          if (data.code == 200) {
            this.handleDialogClose();
            this.$moe_msg.success('重启成功');
            this.$refs['shopDiscountTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      })
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.shopDiscountParams.level = this.levelOptions[this.tabsIndex];
      this.shopDiscountParams.pageNum = 1;
      this.shopDiscountParams.pageSize = 10;
    },
    formatterContents(row) {
      if (!row.method) {
        return '-'
      }
      let string = ''
      if (row.method === 'DISCOUNT') {
        row.contents.forEach((item, index) => {
          if (row.contents.length > 1) {
            let text = `<div>${index + 1}.满${item.condition}件，打${item.discount}折</div>`
            item.text = text;
          } else {
            let text = `满${item.condition}件，打${item.discount}折`
            item.text = text;
          }
        })

        string = row.contents.map(({ text }) => text).join(' ');
      } else if (row.method === 'REDUCE') {
        row.contents.forEach((item, index) => {
          if (row.contents.length > 1) {
            let text = `<div>${index + 1}.满${item.condition}元，减${item.discount}元</div>`
            item.text = text;
          } else {
            let text = `<div>满${item.condition}元，减${item.discount}元</div>`
            item.text = text;
          }
        })

        string = row.contents.map(({ text }) => text).join(' ');
      }
      return string || '-'
    },
    /** 暂停商品折扣活动 */
    handlePause({ id, name }) {
      this.$moe_layer.confirm(`您确定要暂停当前选项"${name}"吗？`, () => {
        this.$moe_api.SHOP_DISCOUNT.pauseDiscount({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('暂停成功');
            this.$refs['shopDiscountTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
    /** 重启商品折扣活动 */
    handlePlay({ id, name, endTime }) {
      if (this.$moe_time.dateBefore({ startDate: endTime })) {
        this.showDialog = true;
        this.restartParams.id = id;
      } else {
        this.$moe_layer.confirm(`您确定要重启当前选项"${name}"吗？`, () => {
          this.$moe_api.SHOP_DISCOUNT.restartDiscount({ id }).then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('重启成功');
              this.$refs['shopDiscountTable'].searchData();
            } else {
              this.$moe_msg.error(data.message);
            }
          });
        });
      }
    },
    /** 关闭商品折扣活动 */
    handleClose({ id, name }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, () => {
        this.$moe_api.SHOP_DISCOUNT.closeDiscount({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('关闭成功');
            this.$refs['shopDiscountTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
  },
  mounted() {
    this.shopDiscountSearch(false);
  }
}
</script>

<style lang="scss">

</style>